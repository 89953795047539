import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Treatment } from "../../../components/Treatment/treatment"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { AssessmentQuiz } from "../../../components/AssessmentQuiz/assessmentQuiz"
import { Faqs } from "../../../components/Faqs/faqs"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { assessmentQuizzesArr } from "../../../mocks/mocks"
import { HeaderMarks } from "../../../components/common/HeaderMarks"
import { returnLink } from "../../../helpers/returnLink"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import PropTypes from "prop-types"

const Treatments = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Treatments",
    description:
      "Restaurer la confiance et la qualité de vie. Des plans de traitement sur mesure sont disponibles et adaptés à vos besoins.",
    url: `https://menshealthclinic.com/${prefix}/treatments`,
  }
  // WEBPAGE SCHEMA END //

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "treatmentmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "treatmentbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Clinique de Santé Masculine Canada | ED & PE Traitement"
        description="Clinique de Santé Masculine propose des plans de traitement personnalisés pour l'éjaculation précoce et la dysfonction érectile, avec des médecins agréés experts. Obtenez une évaluation gratuite aujourd'hui."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/treatments/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/treatments/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/treatments/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/treatments/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/treatments/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/treatments/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/treatments/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="left-blue-semicircle relative mb-145 h-full min-h-420 bg-030654 pb-75 pt-80 max680:mb-60 max680:pb-60 max680:pt-60 max1000:h-auto">
        <HeaderRightSvg className="absolute bottom-0 right-0" />
        <div className="width__container flex items-center justify-between 1200:mb-60 max1200:flex-wrap">
          <div>
            <p className="header__image--dash-text">Men’s Health Clinic</p>
            <h1 className="header__image--title">
              Restaurer la confiance et la qualité de vie.
            </h1>
          </div>
          <div>
            <p className="header__image--text w-full max-w-md pb-25">
              Des plans de traitement sur mesure sont disponibles et adaptés à
              vos besoins.
            </p>
          </div>
        </div>
        <HeaderMarks
          className="width__container flex justify-between text-F5F5F5 max950:block"
          firstText="De vrais médecins canadiens agréés."
          secondText="15,000+ patients traités."
          thirdText="Expédition discrète et soins attentifs."
          fourthText={"Australian Made."}
        />
      </article>
      <main className="mb-180 max680:mb-115">
        <Treatment
          isBtn={true}
          blueText={"Nos traitements"}
          titleText={"Consultez notre gamme de plans de traitement sur mesure."}
          link={
            TextCopies.assessmentQuizLinks(prefix)
              ? TextCopies.assessmentQuizLinks(prefix)
              : returnLink(
                  "Website-Quiz",
                  "Supplementary-Quiz",
                  "treatmenttreatmentquiz",
                  false,
                  prefix
                )
          }
          prefix={prefix}
        />
        <OurProcess prefix={prefix} />
        <AssessmentQuiz
          arr={assessmentQuizzesArr(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmentselfassessquiz",
            false,
            prefix
          )}
          isBtn={false}
          blueText="Quiz d'auto-évaluation"
          titleText="Vous ne savez pas quelle condition vous pourriez avoir ? Faites l'un de nos auto-questionnaires !"
          link={returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "homeselfassessquiz",
            false,
            prefix
          )}
        />
        <Faqs
          blueText="FAQS"
          titleText="Foire aux questions"
          bottomText={true}
          link={returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmentfaqquiz",
            false,
            prefix
          )}
          prefix={prefix}
        />
      </main>
    </Layout>
  )
}
export default Treatments

Treatments.propTypes = {
  prefix: PropTypes.string,
}

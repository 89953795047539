import * as React from "react"
import { CustomButton } from "../../components/common/CustomButton"
import { TextCopies } from "../../helpers/countryTextCopies"
import { OurProcessTextComponents } from "../TextComponents/ourProcess"
import { StaticImage } from "gatsby-plugin-image"
import AUVslVideo from "../../videos/vsl-au.mp4"
import CAVslVideo from "../../videos/vsl-ca.mp4"
import "../../styles/home.css"

export const OurProcess = ({
  prefix,
  title = null,
  treatmentType,
  page = "",
}) => {
  return (
    <section className="process">
      <div className="width__container" id={"howItWorks"}>
        <h2 className="blueText process__blueText">
          {prefix === "fr-ca" ? "Notre processus" : "Our Process"}
        </h2>

        <p className="section__title process__section--title-box">
          {title ? title : "It's easy, convenient and all about you!"}
        </p>

        <div className="home__process--cards-box flex justify-between max1000:flex-col max1400:justify-center">
          <div className="relative mb-40 mr-10 flex w-[100%]  max-w-[275px] flex-1 flex-col items-center max1000:max-w-[100%]">
            <StaticImage
              className="mb-10 h-[275px] w-[100%] rounded-2xl object-cover max1000:h-[315px]"
              src="../../images/mocks/mockupMobile1.webp"
              alt="Mobile Phone Showing Online Treatment Assessment - Men's Health Clinic"
              sizes="(min-width: 1420px) 275px, (min-width: 1020px) calc(21.05vw - 20px), (min-width: 700px) calc(100vw - 200px), calc(100vw - 50px)"
            />
            <p className="absolute left-[5%] top-[137.5px] translate-y-[-50%] text-8xl text-F5F5F5 opacity-60">
              1
            </p>
            <h3 className="mb-3 self-start text-left text-2xl font-semibold text-030654">
              {prefix === "ca"
                ? "Consultation"
                : prefix === "fr-ca"
                ? "Évaluation"
                : "Assessment"}
            </h3>
            <p className="self-start text-left">
              {OurProcessTextComponents.cardOneText(prefix)}
            </p>
          </div>

          <div className="relative mb-40 mr-10 flex w-[100%]  max-w-[275px] flex-1 flex-col items-center max1000:max-w-[100%]">
            <StaticImage
              className="mb-10 h-[275px] w-[100%] rounded-2xl object-cover max1000:h-[315px]"
              src="../../images/mocks/mockupMobile2.webp"
              alt="Doctor Writing on Clipboard - Men's Health Clinic"
              sizes="(min-width: 1420px) 275px, (min-width: 1020px) calc(21.05vw - 20px), (min-width: 700px) calc(100vw - 200px), calc(100vw - 50px)"
            />
            <p className="absolute left-[5%] top-[137.5px] translate-y-[-50%] text-8xl text-F5F5F5 opacity-60">
              2
            </p>
            <h3 className="mb-3 self-start text-left text-2xl font-semibold text-030654">
              {prefix === "fr-ca"
                ? "Consultation médicale"
                : "Doctors Consultation"}
            </h3>
            <p className="self-start text-left">
              {OurProcessTextComponents.cardTwoText(prefix)}
            </p>
          </div>

          <div className="relative mb-40 mr-10 flex w-[100%]  max-w-[275px] flex-1 flex-col items-center max1000:max-w-[100%]">
            <StaticImage
              className="mb-10 h-[275px] w-[100%] rounded-2xl object-cover max1000:h-[315px]"
              src="../../images/mocks/boxBottle2.webp"
              alt="Bottles Medication for Sexual Dysfunction Treatment Showing Discreet Packaging - Men's Health Clinic"
              sizes="(min-width: 1420px) 275px, (min-width: 1020px) calc(21.05vw - 20px), (min-width: 700px) calc(100vw - 200px), calc(100vw - 50px)"
            />
            <p className="absolute left-[5%] top-[137.5px] translate-y-[-50%] text-8xl text-F5F5F5 opacity-60">
              3
            </p>

            <h3 className="mb-3 self-start text-left text-2xl font-semibold text-030654">
              {prefix === "ca"
                ? "Quick and Discreet Delivery"
                : prefix === "fr-ca"
                ? "Livraison discrète"
                : "Discreet Delivery"}
            </h3>
            <p className="self-start text-left">
              {OurProcessTextComponents.cardThreeText(prefix)}
            </p>
          </div>

          <div className="relative mb-40 mr-10 flex w-[100%]  max-w-[275px] flex-1 flex-col items-center max1000:max-w-[100%]">
            <StaticImage
              className="mb-10 h-[275px] w-[100%] rounded-2xl object-cover max1000:h-[315px]"
              src="../../images/mocks/mockupMobile2.webp"
              alt="Mobile Phone Showing Ongoing Patient Support - Men's Health Clinic"
              sizes="(min-width: 1420px) 275px, (min-width: 1020px) calc(21.05vw - 20px), (min-width: 700px) calc(100vw - 200px), calc(100vw - 50px)"
            />
            <p className="absolute left-[5%] top-[137.5px] translate-y-[-50%] text-8xl text-F5F5F5 opacity-60">
              4
            </p>
            <h3 className="mb-3 self-start text-left text-2xl font-semibold text-030654">
              {prefix === "fr-ca" ? "Soutien continu" : "Ongoing Support"}
            </h3>
            <p className="self-start text-left">
              {OurProcessTextComponents.cardFourText(prefix)}
            </p>
          </div>
        </div>

        {page === "home" &&
          (prefix !== "ca" ? (
            <div className="mb-40 block overflow-hidden rounded-20">
              <video
                autoPlay
                muted
                controls
                playsInline
                height={370}
                width={900}
                className="mx-auto rounded-lg"
              >
                <source src={AUVslVideo} type="video/mp4" />
              </video>
            </div>
          ) : (
            <div className="mb-40 block overflow-hidden rounded-20">
              <video
                autoPlay
                muted
                controls
                playsInline
                height={370}
                width={900}
                className="mx-auto rounded-lg"
              >
                <source src={CAVslVideo} type="video/mp4" />
              </video>
            </div>
          ))}

        <CustomButton
          className="orangeFilled slim__btn"
          text={
            prefix === "pe" || prefix === "ed"
              ? "Assess my symptoms now"
              : prefix === "fr-ca"
              ? "Planifiez un rendez-vous"
              : "Schedule an Appointment"
          }
          link={
            treatmentType === "Chronic Pain Treatments & Solutions"
              ? "https://menshealthclinic.as.me/chronicpainwebsite"
              : prefix === "pe" || prefix === "ed"
              ? "https://mhcquiz.typeform.com/to/fS9yRWeG"
              : TextCopies.acuityLink(prefix)
          }
        />
      </div>
    </section>
  )
}
